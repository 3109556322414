.blog_row {
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-bottom: 50px;
}
.blog_wrapper {
    border-radius: 10px;
    overflow: hidden;
    background-color: var(--white);
    box-shadow: 0px 6px 30px rgba(7, 36, 95, 0.08);
    .blog_content {
        padding: 40px;
        .blog_infor {
            display: flex;
            justify-content: flex-start;
            gap: 20px;
            margin-bottom: 15px;
        }
        .infor_item {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 5px;
            .infor_item_icon {
                color: var(--primary-color);
            }
        }
    }
    h2 {
        font-size: 36px;
        font-weight: 700;
        transition: 0.3s ease;
        cursor: pointer;
        margin-bottom: 20px;
        &:hover {
            color: var(--primary-color);
        }
    }
}
