.wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 30px;
    margin-top: 20px;
    overflow: hidden;
}

.form {
    width: 100%;
    display: flex;
    text-align: center;
    background-color: var(--white);
    flex-direction: column;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 1px 6px -2px rgba(50, 71, 92, 0.6705882353);
    h2 {
        margin-bottom: 30px;
        text-align: left;
        font-size: 25px;
    }
}
.tabs {
    display: flex;
    text-align: center;
    flex-direction: column;
    gap: 30px;

    .app-bar {
        background-color: transparent;
        color: var(--black);
        font-size: 30px !important;
    }

    .fields {
        display: flex;
        text-align: center;
        flex-direction: row;
    }
    .infor {
        width: 100%;
        .input_list {
            display: flex;
            flex-direction: row;
            margin-top: 20px;
            gap: 20px;
            margin-bottom: 20px;
            .input,
            .input_wraper,
            .select-content {
                border-color: #adadad69;
            }
            .input_wraper,
            .select {
                width: 50%;
            }
        }
    }
}
.information {
    min-height: 50vh;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .input_list {
        display: flex;
        flex-direction: row;
        gap: 20px;
        justify-content: center;
    }
}
.btn {
    font-size: 14px;
    text-transform: uppercase;
    padding: 10px;
    z-index: 0;
    &:not(:last-child) {
        margin-right: 10px;
    }
}

.img-content {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    overflow: hidden;
}
