.side-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: var(--white);
    padding: 20px;
    width: 100%;
    border-radius: 5px;
    box-shadow: 0 1px 6px -2px #32475cab;

    .input_ {
        flex: 0.7;
        input {
            background-color: var(--smoke-color);
            border-radius: 10px;
            border-color: #adadad69;
        }
    }
    .user {
        flex: 1;
        display: flex;
        flex-direction: row;
        gap: 20px;
        justify-content: flex-end;
        h2 {
            font-size: 25px;
        }
    }
}
