.tour_container {
    padding: 120px 85px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}
.tour_list {
    display: flex;
    flex-direction: column;
    width: 66%;
    margin-bottom: 30px;
}
@media (max-width: 1200px) {
    .tour_container {
        flex-wrap: wrap;
        padding-left: 50px;
        padding-right: 50px;
    }
    .tour_list {
        width: 100%;
    }
}
@media (max-width: 570px) {
    .tour_container {
        padding: 50px 0 !important;
    }
}
