.wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    background-color: #f5f5f9;
}
.container {
    width: 100%;
    padding: 20px;
    overflow: hidden;
}
.content {
    flex: 1;
}
