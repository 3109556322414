.wrapper {
    position: fixed;
    background-color: rgba($color: #000000, $alpha: 0.9);
    z-index: 99999999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    .container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }
}
