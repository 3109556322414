.tours_item {
    background-color: var(--white);
    overflow: hidden;
    border-radius: 10px;
    position: relative;
    margin: 0 15px;
    box-shadow: 0px 2px 15px rgba(22, 25, 33, 0.05);
    &:hover img {
        transform: scale(1.1);
    }
}

.tour_like {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 7px;
    right: 7px;
    width: 35px;
    height: 35px;
    background-color: rgba(22, 25, 33, 0.8);
    border-radius: 10px;
    cursor: pointer;
    transition: 0.5s all ease;

    &:hover {
        background-color: var(--primary-color);
    }
}
.sell,
.count-downt {
    font-size: 15px;
    font-weight: 600;
    position: absolute;
    color: var(--white);
    top: 5px;
    left: 5px;
    padding: 5px 10px;
    border-radius: 10px;
    background-color: red;
    border: 1px solid black;
}
.count-downt {
    top: 45px;
}
.price-offer {
    color: red;
}
.tour_information {
    padding: 25px 25px 0 25px;
    min-width: 405px;
    min-height: 223px;
    h3 {
        margin: 12px 0;
        font-family: 'PlayfairDisplay';
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2; /* Số dòng tối đa */
        -webkit-box-orient: vertical;
        text-overflow: ellipsis;
        line-height: 1.5em; /* Chiều cao của một dòng */
        max-height: 3em; /* 2 dòng * chiều cao của một dòng */
    }
    .sellOff {
        -webkit-line-clamp: 1; /* Số dòng tối đa */
    }
}
.home-tour {
    min-width: auto;
    min-height: auto;
}
.location_re {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.day_persion {
    span:not(:last-child) {
        margin-right: 25px;
    }
    color: var(--text-p-color);
}
.tour_price {
    border-top: 1px dashed #bdbdbd;
    padding-top: 15px;
    padding-bottom: 15px;
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .price {
        color: var(--primary-color);
        font-weight: 500;
    }
    .detail {
        font-weight: 600;
        color: var(--text-p-color);
        font-size: 14px;
        padding-bottom: 2px;
        position: relative;

        &::before {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 0;
            height: 2px;
            background-color: var(--primary-color);
            transition: all ease 0.4s;
        }
        .detail_icon {
            padding-left: 3px;
        }
        &:hover,
        .detail_icon:hover {
            color: var(--primary-color);
        }
        &:hover::before {
            width: 100%;
        }
    }
}
// @media (min-width: 768px) {
//     .tours_item {
//         width: 50%;
//     }
// }
@media (max-width: 570px) {
    .tour_information {
        min-width: 340px !important;
    }
}
