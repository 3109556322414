.pagination {
    display: flex;
    list-style: none;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.page_item {
    width: 50px;
    height: 50px;
    overflow: hidden;
    text-decoration: none;
    font-size: 16px;
    font-weight: 600;
    background-color: var(--smoke-color);
    transition: ease-out 0.3s;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
        background-color: var(--primary-color);
        color: var(--white);
    }
    &:hover .page_link {
        color: var(--white);
    }
}
.page_link {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.active {
    background-color: var(--primary-color);
    .page_link {
        color: var(--white);
    }
}
