.wrapper {
    position: relative;
    textarea {
        border: none;
        width: 100% !important;
        min-height: 115px;
        border-radius: 5px;
        padding: 15px;
        font-size: 16px;
        outline: none;
        border: 1px solid transparent;
        transition: 0.3s ease-in-out;
        &:focus {
            outline: none;
            border-color: var(--primary-color);
        }
    }
    .icon {
        position: absolute;
        top: 34px;
        right: 16px;
    }
}
.label {
    text-align: left;
    display: inline-block;
    width: 100%;
    font-weight: 600;
    margin-left: 5px;
    font-family: 'PlayfairDisplay';
}
