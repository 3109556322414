.wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 30px;
    margin-top: 20px;
}

.form {
    width: 100%;
    display: flex;
    background-color: var(--white);
    flex-direction: column;
    margin: 30px 0;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 1px 6px -2px rgba(50, 71, 92, 0.6705882353);
    h2 {
        margin-bottom: 30px;
        text-align: left;
        font-size: 25px;
    }
    .avatar {
        text-align: center;
        margin-right: 10px;
        .avatar-img {
            overflow: hidden;
            border-radius: 10px;
        }
        img {
            overflow: hidden;
        }
        .avater-btn {
            margin-top: -30px;
            background-color: var(--primary-color);
        }
    }
    .container {
        display: flex;
        flex-direction: column;
        gap: 30px;
        .fields {
            display: flex;
            flex-direction: row;
        }
        .infor {
            width: 100%;
            .input_list {
                display: flex;
                flex-direction: row;
                margin-top: 20px;
                gap: 20px;
                margin-bottom: 20px;
                .input,
                .select-content {
                    background-color: var(--smoke-color);
                    border-color: #adadad69;
                }
                .input_wraper,
                .select {
                    width: 50%;
                }
            }
        }
        .information {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .input_list {
                display: flex;
                flex-direction: row;
                gap: 20px;
                justify-content: center;
            }
            .btn {
                font-size: 14px;
                text-transform: uppercase;
                padding: 10px;
                z-index: 0;
            }
        }
    }
}
.tour {
    margin-top: 27px;
    padding-right: 10px;
}
