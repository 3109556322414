.destination_container {
    padding: 100px 85px;
}

.dest_sort_bar {
    display: flex;
    justify-content: space-between;
    padding: 0 25px;
    margin-bottom: 30px;
    align-items: center;
}

.content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    row-gap: 25px;
}

.content > * {
    flex: 1 1 24%;
    max-width: 24%;
    box-sizing: border-box;
}

/* Đảm bảo khoảng trống giữa các phần tử */
.content > *:nth-child(4n + 1) {
    margin-left: 0;
}

.content > *:nth-child(4n) {
    margin-right: 0;
}

.pagination_ {
    padding: 40px 0 0 0;
}

/* Responsive adjustments */
@media (max-width: 1200px) {
    .destination_container {
        padding: 80px 60px;
    }

    .dest_sort_bar {
        padding: 0 20px;
        margin-bottom: 25px;
    }

    .content > * {
        flex: 1 1 30%;
        max-width: 30%;
    }
}

@media (max-width: 768px) {
    .destination_container {
        padding: 60px 40px;
    }

    .dest_sort_bar {
        flex-direction: column;
        align-items: flex-start;
        padding: 0 15px;
        margin-bottom: 20px;
    }

    .content > * {
        flex: 1 1 45%;
        max-width: 45%;
    }
}

@media (max-width: 480px) {
    .destination_container {
        padding: 40px 20px;
    }

    .dest_sort_bar {
        padding: 0 10px;
        margin-bottom: 15px;
    }

    .content > * {
        flex: 1 1 100%;
        max-width: 100%;
    }

    .pagination_ {
        padding: 30px 0 0 0;
    }
}
