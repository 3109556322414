.wrapper {
    font-size: 25px;
    padding: 40px 40px 40px 20px;
    background-color: var(--white);
    box-shadow: 0 1px 6px -2px #32475cab;
    margin-right: 5px;
}
.menu-list {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .menu_item {
        padding: 10px;
        border-radius: 5px;
        color: var(--text-p-color);
        display: flex;
        flex-direction: row;
        gap: 5px;
        align-items: center;

        h6 {
            font-weight: 100 !important;
            margin-bottom: -7px;
        }
        &:hover {
            color: var(--primary-color);
            background-color: var(--smoke-color);
        }
        &.active {
            color: var(--primary-color);
        }
    }
}
