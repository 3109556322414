.title {
    font-size: 24px;
    position: relative;
    line-height: 1em;
    margin: -0.1em 0 30px 0;
    padding-bottom: 20px;

    &::before,
    &::after {
        content: '';
        height: 2px;
        width: 60px;
        background-color: var(--primary-color);
        position: absolute;
        bottom: 0;
        left: 0;
    }
    &::after {
        left: 65px;
        width: 10px;
    }
}
@media (max-width: 570px) {
    .title {
        text-align: left !important;
        font-size: 20px !important;
    }
}
