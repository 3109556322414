.des_detail_container {
    padding-top: 40px;
    padding-bottom: 40px;
}
.des_detail_top {
    padding: 0 15px;
    h2,
    p {
        margin-bottom: 40px;
    }
    p {
        color: var(--text-p-color);
    }
    .h3 {
        font-size: 35px;
        margin-bottom: 20px;
    }
    .h3:last-of-type {
        margin-top: 20px;
    }
}
.tour_list {
    width: 100%;
}

@media (max-width: 570px) {
    .des_detail_container {
        padding-top: 0 !important;
    }
}
