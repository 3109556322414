.select {
    height: 56px;
    padding-left: 10px;
    cursor: pointer;
    box-shadow: 0 0 0 0px var(--primary-color);
    outline: none;
    &:hover {
        border-color: var(--primary-color);
    }
}
.select-wrapper {
    width: 100%;
}
.label {
    text-align: left;
    display: inline-block;
    width: 100%;
    font-weight: 600;
    margin-left: 5px;
    font-family: 'PlayfairDisplay';
}
