.side-bar {
    padding-left: 15px !important;
}
.tour_row {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    margin-bottom: 50px;
    row-gap: 20px;
    & > * {
        flex-basis: calc(50% - 10px);
        margin: 5px;
    }
}
.pagination_ {
    margin-bottom: 50px;
}
@media (max-width: 570px) {
    .tour_row > * {
        flex-basis: calc(100% - 10px) !important;
    }
}
.background_item {
    padding: 40px;
    background-color: var(--smoke-color);
    margin-bottom: 40px;
    border-radius: 10px;
}
.tour_search {
    position: relative;
    .search-form {
        .icon {
            top: 0;
            right: 0;
            border-radius: 0 5px 5px 0;
            &:hover {
                background-color: var(--black);
            }
            &:hover::before {
                border-radius: 0 5px 5px 0;
            }
        }
    }
}
.no-tours {
    font-size: 20px;
}
.link-tours {
    color: var(--primary-color);
}
