.breadcumb {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    width: 100%;
}
.container {
    padding: 120px 0;
    text-align: center;
    color: var(--white);
    text-transform: capitalize;
    .title {
        font-size: 34px !important;
    }
}
.breadcumb_menu {
    padding: 20px 0;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-size: 18px;
}
.bread_item {
    color: var(--white);
    transition: 0.3s ease-in-out;
    &:hover {
        color: var(--primary-color);
    }
}
@media (max-width: 570px) {
    .container {
        padding: 70px 0 !important;
    }
    .breadcumb_menu {
        padding: 5px 0 !important;
    }
}
