.wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 30px;
    margin-top: 20px;
}
.avatar {
    flex: 0.3;
}

.form {
    flex: 1;
    text-align: center;
    background-color: var(--white);
    padding: 20px;
    margin-right: 20px;
    border-radius: 5px;
    box-shadow: 0 1px 6px -2px rgba(50, 71, 92, 0.6705882353);
    h2 {
        margin-bottom: 30px;
        text-align: left;
        font-size: 25px;
    }
    .input_list {
        display: flex;
        flex-direction: row;
        gap: 20px;
        margin-bottom: 20px;

        .input,
        .select-content {
            background-color: var(--smoke-color);
            border-color: #adadad69;
        }
        .input_wraper,
        .select {
            width: 50%;
        }
    }

    .btn {
        font-size: 14px;
        text-transform: uppercase;
        z-index: 0;
        padding: 10px;
    }
}
.modal {
    position: relative;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    border-radius: 10px;
    border: 1px solid var(--black) !important;
    width: 440px;
    backdrop-filter: blur(20px);
    background-color: var(--white);
    .modal-content {
        position: relative;
        min-height: 300px;
        min-width: 300px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 30px;
    }
    .btnClose {
        position: absolute;
        top: 5px;
        right: 5px;
        width: 30px;
        height: 30px;
    }
    .checkbox-wraper {
        width: 100%;
        padding: 10px;
        font-size: 18px;
        label {
            margin-left: 10px;
        }
    }
    .btnSave {
        text-align: center;
        padding-bottom: 20px;
    }
}
