.login-form,
.register-form,
.forgot-form {
    padding: 40px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    h2 {
        text-align: center;
        margin-bottom: 20px;
        color: var(--white);
    }
}
.loading {
    border-radius: 10px;
}
.success {
    color: var(--primary-color);
}
.error {
    color: red;
}
.forgot-form {
    h2 {
        margin-top: 20px;
    }
}
.logo {
    text-align: center;
}
.form {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 15px;
    min-width: 360px;
    label {
        font-size: 700;
        margin-bottom: -10px;
        color: var(--white);
    }
    .form-input {
        background-color: var(--smoke-color);
        box-shadow: 0px 2px 15px rgba(21, 255, 0, 0.221);
        // font-size: 20px;
        &:hover,
        &:focus {
            box-shadow: 0px 2px 15px rgba(21, 255, 0, 0.521);
        }
    }
}
.btn-close {
    width: 40px;
    height: 40px;
    position: absolute;
    top: 15px;
    right: 15px;
    color: var(--white);
    border-color: var(--white);
}
.login-other {
    font-size: 40px;
    display: flex;
    gap: 30px;
    margin: 20px 0;
    color: var(--white);
}
.form-action {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .remember {
        display: flex;
        align-items: center;
        gap: 5px;
        color: var(--white);
    }
}
.text-link {
    color: var(--white);
}
.action-btn {
    color: var(--white);
    font-weight: 700;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    &:hover {
        color: var(--primary-color);
    }
}
.submit-btn {
    width: 100%;
    padding: 16px;
}
