// banner
.banner {
    position: relative;
    z-index: 2;
    overflow: hidden;
    margin: 10px 10px 10px 10px;
}
.img_hello {
    position: absolute;
    inset: 0;
    background-size: cover;
    background-position: center center;
    border-radius: 15px;

    &::before {
        content: '';
        position: absolute;
        background-image: radial-gradient(
            100% 150.78% at 106% 50%,
            rgba(0, 12, 35, 0) 47.05%,
            rgba(0, 16, 47, 0.63) 47.16%,
            rgba(0, 7, 20, 0.72) 72.61%,
            rgba(0, 5, 15, 0.81) 100%
        );
        inset: 0;
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
    }
}

.container {
    position: relative;
    z-index: 2;
    width: 100%;
    margin: 0 69px;
    padding: 0 15px;
}
.content {
    position: relative;
    z-index: 5;
    padding: 180px 0 260px 0;
    max-width: 650px;
}
.hero_title {
    color: var(--white);
    font-size: 64px;
    font-family: 'PlayfairDisplay';
    &:last-of-type {
        margin-bottom: 35px;
    }
}
.p_title {
    color: #a9afbf;
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 35px;
    font-family: 'Roboto';
    line-height: 2;
}

.tree_1 {
    position: absolute;
    bottom: 0;
    left: 3%;
}
.tree_2 {
    position: absolute;
    bottom: 0;
    left: 40%;
}
.cloud_1,
.cloud_2,
.cloud_3 {
    position: absolute;
    top: 25px;
    left: -220px;
    opacity: 0.3;
    -webkit-animation: cloudAni 30s linear infinite;
    animation: cloudAni 30s linear infinite;
}
.cloud_2 {
    animation-delay: 10s;
    top: 65px;
}
.cloud_3 {
    animation-delay: 20s;
}

@keyframes cloudAni {
    from {
        left: 0;
        transform: translateX(-100%);
    }
    to {
        left: 100%;
        transform: translateX(0%);
    }
}

// search

.suptitle-1000px {
    display: none !important;
}
@media (max-width: 570px) {
    .container {
        margin: 0 5px !important;
        padding: 0 5px !important;
        .hero_title {
            font-size: 34px !important;
        }
        .content {
            padding: 100px 0 160px 0;
        }
    }
    .img_hello {
        overflow: hidden;
        border-radius: 10px !important;
        &::before {
            background-image: radial-gradient(
                100% 140.78% at 160% 50%,
                rgba(0, 12, 35, 0) 47.05%,
                rgba(0, 16, 47, 0.63) 47.16%,
                rgba(0, 7, 20, 0.72) 72.61%,
                rgba(0, 5, 15, 0.81) 100%
            ) !important;
        }
    }
    .tree_1 {
        bottom: 0px;
        img {
            max-width: 140px;
        }
    }
    .tree_2 {
        left: unset;
        right: 10px;
        bottom: -15px;
        img {
            max-width: 140px;
        }
    }
    .service {
        margin: 0 !important;
        padding: 100px 20px !important;
    }
    .category {
        margin: 0 !important;
        padding: 0 20px !important;
    }
    .offers {
        .offers_left {
            min-width: 100px !important;
            padding: 50px 15px !important;
            img {
                max-width: 100%;
            }
        }
        .offers_countdown {
            .count_number {
                font-size: 35px !important;
            }
            li:not(:last-child) {
                padding-right: 25px !important;
                margin-right: 15px !important;
            }
        }
        .offers_right {
            margin: 0 !important;
            padding: 50px 10px !important;
        }
    }
    .map {
        margin-top: 50px !important;
        padding-bottom: 50px !important;
    }
    .featured_container {
        margin: 0 !important;
        padding: 0 10px !important;
        .featured_row {
            flex-direction: column !important;
            gap: 20px;
            margin-bottom: 34px !important;
        }
    }
    .form {
        .form_container {
            margin: 0 !important;
            padding: 0 10px !important;
        }
        .form_content {
            padding: 20px !important;
        }
        .form_input {
            flex-direction: column !important;
            padding: 0 10px !important;
            align-items: center;
            button {
                width: 150px;
            }
        }
    }
    .new {
        padding: 50px 10px !important;
        .new_suptitle {
            flex-direction: column !important;
            gap: 20px;
        }
        .new_information {
            padding: 40px !important;
        }
    }
    .aboutus {
        padding: 150px 0 80px 0 !important;
        .about_left {
            padding: 0 15px !important;
        }
        .about_left_top {
            padding: 10px !important;
        }
        .about_left_bottom {
            gap: 10px;
            .left_bottom_item {
                height: 140px !important;
                width: 100% !important;
                h2 {
                    font-size: 36px !important;
                }
                p {
                    padding: 0 !important;
                }
            }
        }
        .right_img1 {
            .img1 {
                height: 350px;
            }
            .img2 {
                height: 180px !important;
            }
        }
    }
}

@media (max-width: 770px) {
    .container_service {
        width: auto !important;
    }
    .avatar_ {
        display: none !important;
    }
    .information {
        padding: 0 !important;
    }
    .team_box_arrow {
        display: none !important;
    }
}
@media (max-width: 1024px) {
    .tour_search {
        flex-wrap: wrap;
        gap: 16px !important;
    }
    .service {
        flex-wrap: wrap;
        gap: 16px;
    }
    .container_service {
        width: calc(50% - 8px);
    }
    .about_left_top {
        text-align: center;
        p {
            text-align: center !important;
        }
    }
    .suptitle-max {
        display: none !important;
    }
    .suptitle-1000px {
        display: flex !important;
        justify-content: center;
    }
    .about_right {
        margin-top: 30px;
    }
    .img2 {
        height: 350px;
        object-fit: cover;
    }

    .featured {
        background-image: none !important;
        padding: 80px 0 !important;
    }
    .team_box {
        margin-left: 50px !important;
        margin-right: 50px !important;
        width: 80% !important;
    }
    .team {
        padding: 20px 0 !important;
    }
    .form_wrap {
        flex-direction: column !important;
        align-items: stretch !important;
        .form_content {
            padding: 50px;
            h2 {
                font-size: 36px;
            }
            p {
                margin-bottom: 20px !important;
            }
        }
    }
    .new {
        padding: 50px 85px;
    }
}
@media (max-width: 1200px) {
    .offers {
        flex-direction: column;
        .offers_right {
            max-width: 100% !important;
            padding-top: 50px !important;
        }
        .offers_header {
            justify-content: center !important;
            flex-direction: column !important;
            align-items: center !important;
            margin-bottom: 30px;
        }
        .header_title {
            margin-bottom: 10px !important;
        }
        .offers_list {
            padding-bottom: 50px;
        }
    }
    .map_row {
        flex-direction: column-reverse;
        .map_mask {
            margin: 0 0 50px 0 !important;
            img {
                width: 100%;
            }
        }
    }
}
// service
.service {
    display: flex;
    justify-content: space-between;
    padding: 120px 0;
    margin: 0 85px;
    .container_service {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .img_service {
        background-size: cover;
        background-position: center center;
    }

    .title_service {
        margin: 15px 0;
        font-family: 'PlayfairDisplay';
        font-size: 20px;
    }

    .content_service {
        text-align: center;
        color: var(--text-p-color);
        line-height: 1.75;
    }
}

// slider
.category {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 85px;

    h2 {
        margin-bottom: 15px;
    }

    ._row {
        display: block;
        width: 100%;
        padding: 30px 0;
    }

    .bottom_img {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: var(--white);
        padding: 15px 20px;
        gap: 10px;

        .category_name {
            font-size: 18px;
            font-family: 'PlayfairDisplay';
            cursor: pointer;
            &:hover {
                color: var(--primary-color);
            }
        }
        .category_trip {
            font-size: 18px;
            color: var(--primary-color);
        }
    }
}

// aboutus
.aboutus {
    display: flex;
    flex-direction: row;
    padding: 150px 85px 50px 85px;
    margin-top: -87px;
    flex-wrap: wrap;
    background-color: var(--smoke-color);
    .about_left {
        flex: 1;
        padding-right: 40px;
        .about_left_top {
            padding: 0 48px 0 15px;
            h2 {
                line-height: 1.227;
                margin: -15px 0 35px 0;
            }

            p {
                text-align: left;
                color: var(--text-p-color);
                line-height: 1.75;
                margin-bottom: 40px;
            }
        }

        .about_left_bottom {
            display: flex;
            flex-direction: row;
            justify-content: space-around;
        }

        .left_bottom_item {
            background-color: var(--white);
            width: 180px;
            height: 180px;
            border-radius: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            h2 {
                font-family: 'Roboto';
                color: var(--primary-color);
            }
            p {
                line-height: 1.75;
                padding: 0 20px;
                text-align: center;
                color: var(--text-p-color);
            }
        }
    }

    .about_right {
        flex: 1;
        position: relative;

        .right_img1 {
            text-align: right;
            position: relative;
            z-index: 2;
        }

        .right_img2 {
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 50%;
            overflow: hidden;
            background-color: var(--white);
            border: 10px solid var(--white);
        }
        .right_img3 {
            position: absolute;
            top: 0px;
            left: 35px;
            z-index: -1;
            animation: jumpReverseAni 7s linear infinite;
        }
        @keyframes jumpReverseAni {
            0% {
                -webkit-transform: translateY(0);
                transform: translateY(0);
            }

            50% {
                -webkit-transform: translateY(30px);
                transform: translateY(30px);
            }

            100% {
                -webkit-transform: translateY(0);
                transform: translateY(0);
            }
        }
    }
}

// destination
.destination {
    padding: 120px 85px;
    margin: 0;
    background-color: var(--white);
}

//offers
.offers {
    display: flex;
    background-color: #1b4d31;

    .offers_left {
        min-width: 580px;
        background-color: var(--title-color);
        background-size: auto;
        background-repeat: repeat;
        text-align: center;
        padding: 120px 10px;
        .offers_countdown {
            margin-top: 90px;
            list-style-type: none;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            li {
                color: var(--white);
                text-align: center;
            }
            .count_number {
                font-size: 40px;
                margin-top: -0.14em;
                margin-bottom: 4px;
                font-weight: 700;
                line-height: 1;
            }

            li:not(:last-child) {
                padding-right: 35px;
                margin-right: 28px;
                display: inline-block;
                position: relative;

                &::after {
                    content: ':';
                    font-size: 40px;
                    font-weight: bold;
                    color: var(--primary-color);
                    position: absolute;
                    top: -7px;
                    right: 0;
                    font-weight: 900;
                }
            }
        }
    }

    .offers_right {
        padding: 50px 70px 50px 0;
        width: 100%;
        max-width: calc(100% - 600px);
        margin-left: 20px;

        .offers_header {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;
        }
        .header_title {
            margin-bottom: 48px;
            padding: 0 15px;
            h2 {
                color: var(--white);
                margin-top: -20px;
                margin-bottom: 15px;
            }
        }
        .arrow_list {
            padding: 0 15px;
            .arrow_button {
                background-color: rgba(255, 255, 255, 0.1);
                color: var(--white);
                border-color: rgba(255, 255, 255, 0.1);
                &:not(:last-child) {
                    margin-right: 20px;
                }
            }
        }
        .offers_list {
            display: block;
            width: 100%;
        }
    }
}

//map
.map {
    .map_row {
        display: flex;
        flex-wrap: wrap;
        padding-top: 20px;
        .process_list_wrap {
            flex: 1;
            position: relative;

            &::before {
                content: '';
                height: calc(100% - 60px);
                position: absolute;
                top: 25px;
                left: 27px;
                border-left: 2px dashed var(--primary-color);
            }
            .process-list {
                display: flex;
                gap: 20px;

                &:not(:last-child) {
                    margin-bottom: 40px;
                }
            }

            .process-list__number {
                font-size: 24px;
                font-weight: 700;
                color: var(--primary-color);
                min-width: 56px;
                height: 56px;
                line-height: 56px;
                background-color: #ebfff4;
                border-radius: 50%;
                text-align: center;
                position: relative;
                z-index: 3;
            }

            .media-body {
                flex: 1;
            }

            .process-list__title {
                font-size: 20px;
                line-height: 30px;
                margin-top: -0.3em;
                margin-bottom: 13px;
                text-transform: capitalize;
                font-family: 'PlayfairDisplay';
            }

            .process-list__text {
                font-size: 14px;
                line-height: 24px;
                margin-bottom: -0.5em;
                color: var(--text-p-color);
            }
        }
        .map_mask {
            flex: 1.3;
            position: relative;
            margin-left: 60px;
            .map_mask_img {
                mask-size: 100% 100%;
            }
            .dot {
                position: absolute;
                height: 15px;
                width: 15px;
                background-color: var(--primary-color);
                border-radius: 50%;
                z-index: 2;
                cursor: pointer;
                &:hover .trips {
                    visibility: visible;
                    opacity: 1;
                    transform: translateX(-50%) scale(1);
                }
                .trips {
                    background-color: var(--title-color);
                    display: inline-block;
                    color: var(--white);
                    border-radius: 5px;
                    font-size: 14px;
                    padding: 0 10px;
                    line-height: 30px;
                    white-space: nowrap;
                    position: absolute;
                    top: -50px;
                    left: 50%;
                    transform: translateX(-50%) scale(0.5);
                    transform-origin: bottom center;
                    visibility: hidden;
                    opacity: 0;
                    transition: 0.3s ease-in-out;
                }
                &:hover.trips {
                    visibility: visible;
                    opacity: 1;
                    transform: translateX(-50%) scale(1);
                }
                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    background-color: var(--primary-color);
                    animation: ripplemap 5s ease-in-out infinite;
                    z-index: -1;
                    border-radius: inherit;
                    transition: all ease 0.4s;
                }

                &::after {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    background-color: var(--primary-color);
                    -webkit-animation: ripplemap 5s ease-in-out infinite;
                    animation: ripplemap 5s ease-in-out infinite;
                    z-index: -1;
                    border-radius: inherit;
                    -webkit-transition: all ease 0.4s;
                    transition: all ease 0.4s;
                }
                &::after {
                    animation-delay: 2s;
                }
                @keyframes ripplemap {
                    0% {
                        -webkit-transform: scale(1);
                        transform: scale(1);
                        opacity: 0;
                    }

                    30% {
                        opacity: 0.6;
                    }

                    100% {
                        -webkit-transform: scale(2.5);
                        transform: scale(2.5);
                        opacity: 0;
                    }
                }
            }
        }
    }
}

//featured
.featured {
    padding: 100px 0;
    background-color: var(--smoke-color);
    background-repeat: no-repeat;
    background-size: auto;
    background-position: right center;
    .featured_container {
        margin: 0 85px;
        .featured_row {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 0 15px;
        }
        .featured_tour {
            margin-top: 20px;
        }
    }
}
//team
.team {
    padding: 120px 0;
    position: relative;

    .avatar_ {
        position: absolute;
        animation-delay: 2s;
        animation: testiPulse 7s linear infinite;
    }
    @keyframes testiPulse {
        0% {
            transform: scale(0.7) translate(40px, 40px);
        }

        50% {
            transform: scale(1) translate(0, 0);
        }

        100% {
            transform: scale(0.7) translate(40px, 40px);
        }
    }
    .team_box {
        margin: 0 200px;
        margin-top: 35px;
        width: 60%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-direction: row;
        overflow: hidden;

        .team_box_slider {
            width: 80%;
            overflow: hidden;
            flex: 1 1 auto;
        }
        .team_box_arrow {
            flex: 0 0 auto;
        }
        .team_box_account {
            display: flex !important;
            flex-direction: column;
            align-items: center;
            .avatar {
                margin-bottom: 20px;
            }
            p {
                font-size: 15px;
                color: var(--primary-color);
            }
            .information {
                padding: 0 70px;
                margin-bottom: 30px;
                text-align: center;
                p {
                    color: var(--black);
                    font-size: 20px;
                    font-style: italic;
                    line-height: 30px;
                }
            }
            .start {
                margin-bottom: 20px;
            }
            h2 {
                font-size: 20px;
                margin-bottom: 5px;
            }
        }
    }
}
//from
.form {
    padding-top: 120px;
    background-image: linear-gradient(to top, var(--smoke-color), var(--smoke-color));
    background-size: 100% 505px;
    background-position: top center;
    background-repeat: no-repeat;
    .form_container {
        max-width: 1720px;
        margin: 0 85px;
        .form_wrap {
            display: flex;
            flex-direction: row;
            margin: 0 auto;
            align-items: center;
            background-color: var(--white);
            justify-content: center;
            box-shadow: 0px 10px 50px rgba(22, 25, 33, 0.07);
            .form_img {
                flex: 1.5;
            }
        }
        .form_content {
            flex: 2;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            h2 {
                text-align: center;
                margin-bottom: 15px;
            }
            p {
                font-size: 18px;
                margin-bottom: 60px;
                color: var(--text-p-color);
            }
            .form_input {
                display: flex;
                width: 100%;
                padding: 0 50px;
                gap: 10px;
                input {
                    width: 100%;
                    border: 1px solid #e4e4e4;
                    color: var(--black);
                    background-color: var(--smoke-color);
                    height: 56px;
                    font-size: 18px;
                    padding: 0 20px 0 20px;
                    border-radius: 5px;
                    transition: 0.3s ease-in-out;
                    &:focus {
                        background-color: var(--white);
                        border-color: var(--title-color);
                    }
                }
            }
        }
    }
}
//new
.new {
    padding: 120px;
    .new_slider {
        margin-top: 40px;
    }
    .new_suptitle {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 15px;
    }
    .new_item {
        padding: 0 15px;
        row-gap: 30px;
        display: flex !important;
        flex-direction: column;
    }

    .new_information {
        padding: 40px;
        width: 100%;
        height: 270px;
        background-color: var(--smoke-color);
        .new_item_time {
            margin-bottom: 20px;
            display: flex;
            flex-direction: row;
            gap: 10px;
            span {
                font-size: 14px;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 5px;
                color: var(--text-p-color);
            }
        }
        .new_title {
            font-size: 24px;
            font-weight: 600;
            font-family: 'PlayfairDisplay';
            margin-bottom: 25px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2; /* Số dòng tối đa */
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            line-height: 1.5em; /* Chiều cao của một dòng */
            max-height: 3em; /* 2 dòng * chiều cao của một dòng */
        }
        .new_read_more {
            font-weight: 600;
            font-size: 14px;
            display: inline-block;
            line-height: 0.8;
            position: relative;
            padding-bottom: 2px;
            margin-bottom: -2px;
            text-transform: uppercase;
            color: var(--primary-color);

            &::before {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                width: 0;
                height: 2px;
                background-color: var(--primary-color);
                transition: all ease 0.4s;
            }
            &:hover::before {
                width: 100%;
            }
        }
    }
}
.right_img3 {
    display: none !important;
}
