.container {
    display: flex;
    flex-direction: row;
    margin: 120px 0;
    justify-content: center;
    align-items: center;
    .tabs {
        background-color: var(--smoke-color);
        overflow: hidden;
        margin-left: 20px;
        width: 50%;
        border-radius: 15px;
        .app-bar {
            background-color: transparent;
            color: var(--black);
        }
    }
    .tab-content {
        padding: 20px;
        border: 1px solid rgba(0, 0, 0, 0.354);
        border-radius: 10px;
    }
    .from-submit {
        h4 {
            font-weight: 600;
            margin-bottom: 15px;
        }
        .input-wraper {
            display: flex;
            flex-direction: column;
            margin: 15px 0;
        }
        .date-cvv {
            display: flex;
            flex-direction: row;
            gap: 20px;
        }
        .date {
            display: flex;
            flex-direction: row;
            gap: 2px;
        }
        .input {
            border: 1px solid rgba(0, 0, 0, 0.354);
            height: 47px;
        }
        .input-icon-right {
            top: 9px;
        }
        .btn-submit {
            display: flex;
            text-align: center;
            justify-content: center;
            margin-top: 15px;
            padding: 10px 20px;
            background-color: rgba(0, 0, 0, 0.126);
            border-top: 1px solid rgba(0, 0, 0, 0.354);
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            width: 100%;

            .btn {
                width: 100%;
                height: 40px;
            }
        }
    }
    .paypal {
        h4 {
            margin-bottom: 20px;
        }
        .option {
            display: flex;
            flex-direction: row;
            gap: 50px;
            margin-bottom: 20px;
            label {
                margin-left: 5px;
            }
        }
        .bank-select {
            width: 100%;
        }
        .select-content {
            height: 47px;
        }
        .btn-submit {
            margin-bottom: 20px;
            .btn {
                z-index: 0;
                height: 40px;
            }
        }
    }
}
