.tour_information {
    background-color: var(--smoke-color);
    border-radius: 10px;
    padding: 35px 40px;
    h2 {
        margin-bottom: 20px;
        font-size: 30px;
    }
    .information_list {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .information_item {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 10px;
            .information-icon {
                color: var(--primary-color);
                padding: 6px;
                border-radius: 50%;
                border: 1px solid #e4e4e4;
                background-color: var(--white);
                text-align: center;
                margin: 0 auto;
            }
            span {
                font-family: 'PlayfairDisplay';
                color: var(--black);
                font-size: 20px;
                font-weight: 500;
            }
            p {
                color: var(--text-p-color);
            }
        }
    }
}
.tour_content {
    overflow: hidden;
    background-color: var(--smoke-color);
    border-radius: 10px;
    width: 100%;
    padding: 10px;
    margin: 30px 0;
}
.tour_review {
    background-color: var(--smoke-color);
    padding: 40px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    .p-title {
        color: var(--text-p-color);
    }
    h2 {
        font-size: 30px;
        font-weight: 700;
        margin-bottom: -15px;
    }
    .start-wrapper {
        display: flex;
        flex-direction: row;
        gap: 10px;
        font-size: 16px;
    }
    .rating_form {
        display: flex;
        gap: 15px;
        margin-top: -25px;
        flex-direction: column;
    }
    .rating_input {
        display: flex;
        flex-direction: row;
        gap: 15px;
        .rating_input_item {
            width: 100%;
        }
        input {
            border: 1px solid transparent;
            border-radius: 5px;
            &:focus {
                border-color: var(--primary-color);
            }
        }
    }
    .rating_check {
        display: flex;
        flex-direction: row;
        gap: 10px;
    }
    .rating_btn {
        width: 160px;
    }
}
.review-list {
    margin-top: 30px;
    .review-item {
        display: flex;
        flex-direction: row;
        gap: 10px;
        padding-bottom: 15px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.205);
    }
    .name {
        font-weight: 600;
    }
    .time {
        font-size: 13px;
    }
    .image-list {
        display: flex;
        flex-direction: row;
        padding: 10px 0;
        gap: 5px;
    }
}
@media (max-width: 570px) {
    .information_list {
        flex-wrap: wrap;
    }
    .information_list > * {
        flex-basis: calc(50% - 10px);
        margin: 5px;
    }
    .information_item {
        flex-direction: column !important;
        .information_ {
            text-align: center;
        }
    }
}
